<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Diagnose - Huisarts</h1>
        <div class="row">
          <p class="intro-section-text" v-if="selectedYear !== 2020 && selectedYear !== 2021">
            Deze pagina geeft informatie over bezoek aan de huisarts en huisartsenpost.
          </p>
          <p class="intro-section-text" v-if="selectedYear === 2020 || selectedYear === 2021">
            Deze pagina is niet beschikbaar voor <em><b class="blue">2020 en 2021</b></em>.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <card :cardId="`Gemiddeld aantal contacten met de huisarts in ${selectedYear}`"
          v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patienten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens'"
          :x="leeftijd_alle" :y="gem_contact_alle.map(value => `${value}%`)" :xtitle="'Leeftijd'"
          :ytitle="'Gemiddeld aantal contacten'" :tableHeight="350">
          <template slot="card-header">Gemiddeld aantal contacten met de huisarts in {{ selectedYear }}</template>
          <div slot="card-body">
            Gemiddelde aantal huisartscontacten per persoon onder mensen met dementie die ingeschreven zijn bij een
            huisartsenpraktijk in vergelijking met gemiddelden uit de algemene bevolking (op basis van Nivel
            Zorgregistraties Eerste Lijn). Hieronder vallen: consulten, visites, telefonisch consulten en e-mail consulten
            met de huisarts of de praktijkondersteuner GGZ.
            <grouped-bar-chart v-if="load.graphOne" :names="['Mensen met dementie']" :x="[leeftijd_dem]"
              :y="[gemiddeld_contact]" :showlegend="false" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-1-1'" v-if="selectedYear===2020">
            <template slot="card-header">Gemiddeld aantal contacten met de huisarts in {{selectedYear}} - niet beschikbaar voor 2020</template>
        </card-small> -->
      </div>


      <div class="col-sm-6">
        <card :cardId="`Ten minste één contact met de huisarts in ${selectedYear}`"
          v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patienten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens'"
          :x="leeftijd_alle" :y="minstens_een_alle.map(value => `${value}%`)" :xtitle="'Leeftijd'" :ytitle="'Percentage'"
          :tableHeight="350">
          <template slot="card-header">Ten minste één contact met de huisarts in {{ selectedYear }}</template>
          <div slot="card-body">
            Percentage mensen met dementie met ten minste één huisartscontact in vergelijking met de algemene bevolking
            (op basis van Nivel Zorgregistraties Eerste Lijn).
            <br><br><br>
            <grouped-bar-chart v-if="load.graphOne" :names="['Mensen met dementie']" :x="[leeftijd_dem]"
              :y="[minstens_een]" :showlegend="false" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-2-1'" v-if="selectedYear===2020">
          <template slot="card-header">Ten minste één contact met de huisarts in {{selectedYear}} - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 down">
        <card :cardId="`Top 10 redenen voor contact met huisarts voor mensen met dementie in ${selectedYear}`"
          v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patiënten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens. Bron: Nivel Zorgregistraties Eerste Lijn.'"
          :x="diagnose_dementie" :y="aantal_dementie" :xtitle="'Redenen'" :ytitle="'Aantal contacten'" :tableHeight="520">
          <template slot="card-header">Top 10 redenen voor contact met huisarts voor mensen met dementie in
            {{ selectedYear }}</template>
          <div slot="card-body">
            Deze grafiek geeft de top 10 redenen voor contact met huisarts weer voor mensen met dementie in
            {{ selectedYear }}.
            <horizontal-bar-chart-numbers v-if="load.graphTwo"
              :xtitle="'Aantal contacten met de huisarts met reden per 100 mensen met dementie'" :x="aantal_dementie"
              :y="diagnose_dementie" :marginleft="300" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-4-1'" v-if="selectedYear===2020">
          <template slot="card-header">Top 10 redenen voor contact met huisarts voor mensen met dementie in {{selectedYear}} - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 down">
        <card :cardId="`Reden voor contact met huisarts per leeftijdsgroep in ${selectedYear}`"
          v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Diagnoses per leeftijdsgroep. A05 komt niet voor bij mensen onder de 65 in deze grafiek omdat deze diagnose niet in de top 10 diagnoses van deze leeftijdsgroep zit. Voor huisartsenzorg zijn dit gegevens over de patiënten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens. Bron: Nivel Zorgregistraties Eerste Lijn.'"
          :x="diagnose_dementie_top5" :y="diagnose_alles_table" :z="diagnose_65_table" :w="diagnose_79_table"
          :v="diagnose_89_table" :t="diagnose_99_table" :xtitle="'Patiënt titel'" :ytitle="'Totaal'" :ztitle="'<65 jaar'"
          :wtitle="'65-79 jaar'" :vtitle="'80-89 jaar'" :ttitle="'90+ jaar'" :tableHeight="400">
          <template slot="card-header">Reden voor contact met huisarts per leeftijdsgroep in {{ selectedYear }}</template>
          <div slot="card-body">
            Reden voor contact met huisarts verdeeld per leeftijd
            <grouped-bar-chart :xtitle="'Reden voor contact met huisarts'"
              :ytitle="'Bezoeken per 100 mensen met dementie'"
              :names="['totaal mensen met dementie', '<65 jaar', '65-79 jaar', '80-89 jaar', '90+ jaar']" :margintop="200"
              :height="400"
              :x="[diagnose_dementie_top5, diagnose_dementie_top5, diagnose_dementie_top5, diagnose_dementie_top5, diagnose_dementie_top5]"
              :y="[diagnose_alles, diagnose_65, diagnose_79, diagnose_89, diagnose_99]" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-5-1'" v-if="selectedYear===2020">
          <template slot="card-header">Redenen voor contact met huisarts per leeftijdsgroep in {{selectedYear}} - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>
    </div>
    <!-- <div class="col-sm-12 down">
      <card :hovertext="'Diagnoses per leeftijdsgroep. A05 komt niet voor bij mensen onder de 65 in deze grafiek omdat deze diagnose niet in de top 10 diagnoses van deze leeftijdsgroep zit.'">
        <template slot="card-header"> Leeftijdsgroep diagnoses</template>
        <div slot="card-body">
          Diagnoses per leeftijdsgroep
            <grouped-bar-chart :xtitle="'Leefijdsgroep'" :ytitle="'% met diagnose'" :data1="[{ type: 'bar', name: diagnoses[0],
            x: diagnose_dementie_1, y:aantal_dementie_1, marker: {
            color: '#2E5984'}}, { type: 'bar', name: diagnoses[1],
            x: diagnose_dementie_2, y:aantal_dementie_2, marker: {
            color: '#528AAE'}}, { type: 'bar', name: diagnoses[2],
            x: diagnose_dementie_3, y:aantal_dementie_3, marker: {
            color: '#73A5C6'}}, { type: 'bar', name: diagnoses[3],
            x: diagnose_dementie_4, y:aantal_dementie_4, marker: {
            color: '#91BAD6'}}, { type: 'bar', name: diagnoses[4],
            x: diagnose_dementie_5, y:aantal_dementie_5, marker: {
            color: '#BCD2E8'}}]"/>
        </div>
      </card>
    </div> -->
    <div class="row">
      <div class="col-sm-6 down">
        <card :cardId="'Gemiddeld aantal contacten met de huisartsenpost'"
          v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patienten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens'"
          :x="leeftijd_dem_hap" :y="gemiddeld_contact_hap" :xtitle="'Leeftijd groep'" :ytitle="'Gemiddeld contact'"
          :tableHeight="350">
          <template slot="card-header">Gemiddeld aantal contacten met de huisartsenpost</template>
          <div slot="card-body">
            Gemiddelde aantal huisartsenpostcontacten per 1000 personen met dementie (op basis van Nivel Zorgregistraties
            Eerste Lijn).
            <grouped-bar-chart v-if="load.graphFour" :names="['Mensen met dementie']" :x="[leeftijd_dem_hap]"
              :y="[gemiddeld_contact_hap]" :showlegend="false" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-6-1'" v-if="selectedYear===2020">
          <template slot="card-header">Gemiddeld aantal contacten met de huisartsenpost - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>

      <div class="col-sm-6 down">
        <card :cardId="'Ten minste één contact met de huisartsenpost'"
          v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patienten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens'"
          :x="leeftijd_dem_hap" :y="minstens_een_hap" :xtitle="'Leeftijd groep'" :ytitle="'Aantal contacten'"
          :tableHeight="350">
          <template slot="card-header">Ten minste één contact met de huisartsenpost</template>
          <div slot="card-body">
            Percentage mensen met dementie met ten minste één huisartsenpostcontact in vergelijking met de algemene
            bevolking (op basis van Nivel Zorgregistraties Eerste Lijn).
            <grouped-bar-chart v-if="load.graphFour" :names="['Mensen met dementie']" :x="[leeftijd_dem_hap]"
              :y="[minstens_een_hap]" :showlegend="false" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-7-1'" v-if="selectedYear===2020">
          <template slot="card-header">Ten minste één contact met de huisartsenpost - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <card :cardId="'Urgentie contacten huisartsenpost'" v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patiënten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere CBS-gegevens. Bron: Nivel Zorgregistraties Eerste Lijn.'"
          :x="urgentie" :y="urgentie_percentage_table.map(value => `${value}%`)" :xtitle="'Urgentie'"
          :ytitle="'Percentage'" :tableHeight="350">
          <template slot="card-header">Urgentie contacten huisartsenpost</template>
          <div slot="card-body">
            Urgentie van contacten met de huisartsenpost (in % van het totale aantal contacten met de huisartsenpost)
            onder mensen met dementie in vergelijking met de urgentie van de contacten in de algemene bevolking (op basis
            van Nivel Zorgregistraties Eerste Lijn).
            <stacked-bar-chart-colors-x-title v-if="load.graphFive" :ytitle="'% met urgentietype'" :tickangle="0"
              :height="440" :name1="'U1 - levensbedreigend'" :x1="['Mensen met dementie']" :y1="u1" :name2="'U2 - spoed'"
              :x2="['Mensen met dementie']" :y2="u2" :name3="'U3 - dringend'" :x3="['Mensen met dementie']" :y3="u3"
              :name4="'U4 - routine'" :x4="['Mensen met dementie']" :y4="u4" :name5="'U5 - advies'"
              :x5="['Mensen met dementie']" :y5="u5" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-8-1'" v-if="selectedYear===2020">
          <template slot="card-header">Urgentie contacten huisartsenpost - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>

      <div class="col-sm-6">
        <card :cardId="'Verdeling soort contacten huisarts'" v-if="selectedYear !== 2020 && selectedYear !== 2021"
          :hovertext="'Voor huisartsenzorg zijn dit gegevens over de patiënten van de huisartsen die data aan hebben geleverd; bij de gegevens over huisartsenposten zijn dat alle mensen met dementie die in een postcode (4-cijferig) wonen in het verzorgingsgebied van een huisartsenpost die data aanlevert. In beide gevallen geldt dat alleen resultaten zijn gerapporteerd voor personen voor wie de gegevens koppelbaar zijn aan de andere databronnen binnen CBS. Deze gegevens zijn beschikbaar van 2017-2019. Bron: Nivel Zorgregistraties Eerste Lijn - NZR.'"
          :x="leeftijd_dem" :y="consult_table.map(value => `${value}%`)" :z="visite_table.map(value => `${value}%`)"
          :w="kort_table.map(value => `${value}%`)" :v="poh_table.map(value => `${value}%`)" :xtitle="'Leeftijd'"
          :ytitle="'Consult'" :ztitle="'Visite'" :wtitle="'Kort'" :vtitle="'POH'" :tableHeight="500">
          <template slot="card-header">Verdeling soort contacten huisarts</template>
          <div slot="card-body">
            Soorten contacten met de huisarts (in % van het totale aantal contacten met de huisarts) onder mensen met
            dementie (op basis van Nivel Zorgregistraties Eerste Lijn – NZR).
            <stacked-bar-chart-colors-x-title v-if="load.graphOne" :ytitle="'% soort contact met huisarts'" :tickangle="0"
              :height="440" :name1="'Consult'" :x1="['Mensen met dementie']" :y1="consult" :name2="'Visite'"
              :x2="['Mensen met dementie']" :y2="visite" :name3="'Kort'" :x3="['Mensen met dementie']" :y3="kort"
              :name4="'POH'" :x4="['Mensen met dementie']" :y4="poh" />
          </div>
        </card>
        <!-- <card-small :cardId="'diagnose-huisarts-3-1'" v-if="selectedYear===2020">
          <template slot="card-header">Verdeling soort contacten huisarts - niet beschikbaar voor 2020
          </template>
        </card-small> -->
      </div>
    </div>

    <div class="row custom-text">
      <p>Het gebruik van gegevens uit elektronische patiëntendossiers, zoals verzameld door Nivel Zorgregistraties Eerste
        lijn, is onder bepaalde voorwaarden toegestaan, zonder dat van iedere afzonderlijke patiënt daarvoor toestemming
        wordt gevraagd of dat toetsing door een medisch ethische commissie heeft plaatsgevonden (art. 24 UAVG ja art. 9.2
        sub j AVG). </p>

      <p>Deze studie is goedgekeurd volgens de governance code van <a class="custom-link"
          href="https://www.nivel.nl/nl/nivel-zorgregistraties-eerste-lijn">Nivel Zorgregistraties,</a>onder nummer
        NZR-00321.048.</p>
    </div>

    <div class="row custom-text" style="margin-top: 2vw">
      <p>
        Voor het definieren van de contacten zijn de volgende declaratiecodes gebruikt
      </p>
    </div>

    <div>
      <table class="table custom-margin blue custom-font">
        <thead class="table-bg">
          <tr>
            <th scope="col"></th>
            <th scope="col">2017-2018</th>
            <th scope="col">2019</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Consult</th>
            <td>12000 12001</td>
            <td>12001 12011</td>
          </tr>
          <tr>
            <th scope="row">Visite</th>
            <td>12002 12003</td>
            <td>12002 12003</td>
          </tr>
          <tr>
            <th scope="row">Telefonisch consulten en e-mail consulten</th>
            <td>12004 12007</td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">Kort consult</th>
            <td></td>
            <td>12010</td>
          </tr>
          <tr>
            <th scope="row">POH</th>
            <td>12110 12111 12112 12113 12114 12115</td>
            <td>12111 12112 12113 12117 12118</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
  </div>
</template>


<script>
import Card from '../components/Card'
// import CardSmall from '../components/CardSmall'
import GroupedBarChart from '../components/GroupedBarChart'
import StackedBarChartColorsXTitle from '../components/StackedBarChartColorsXTitle'
import HorizontalBarChartNumbers from '../components/HorizontalBarChartNumbers'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Diagnose - Huisarts',
  },
  components: { Card, HorizontalBarChartNumbers, StackedBarChartColorsXTitle, GroupedBarChart, Footer },
  data() {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      gemiddeld_contact: [],
      minstens_een: [],
      diagnose_dementie: [],
      aantal_dementie: [],
      consult: [],
      visite: [],
      kort: [],
      poh: [],
      consult_table: [],
      visite_table: [],
      kort_table: [],
      poh_table: [],
      gemiddeld_contact_hap: [],
      minstens_een_hap: [],
      diagnose_dementie_hap: [],
      aantal_dementie_hap: [],
      consult_hap: [],
      visite_hap: [],
      telefonisch_hap: [],
      diagnose_dementie_top5: [],
      diagnose_65: [],
      diagnose_79: [],
      diagnose_89: [],
      diagnose_99: [],
      diagnose_alles: [],
      diagnose_65_table: [],
      diagnose_79_table: [],
      diagnose_89_table: [],
      diagnose_99_table: [],
      diagnose_alles_table: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false
      },
      diagnose_dementie_1: [],
      aantal_dementie_1: [],
      diagnose_dementie_2: [],
      aantal_dementie_2: [],
      diagnose_dementie_3: [],
      aantal_dementie_3: [],
      diagnose_dementie_4: [],
      aantal_dementie_4: [],
      diagnose_dementie_5: [],
      aantal_dementie_5: [],
      diagnoses: [],
      leeftijd_dem: [],
      leeftijd_dem_hap: [],
      u1: [],
      u2: [],
      u3: [],
      u4: [],
      u5: [],
      urgentie: [],
      urgentie_percentage: [],
      urgentie_percentage_table: [],
      leeftijd_alle: [],
      gem_contact_alle: [],
      minstens_een_alle: [],
    }
  },
  watch: {
    '$store.state.selectedYear': function () {
      this.loadAllData()
    }
  },
  methods: {
    loadAllData() {
      this.$http.get(`api/contacten/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.gemiddeld_contact = response.data.map(d => (d.gem_contact))
          this.leeftijd_dem = response.data.map(d => (d.leeftijd))
          this.minstens_een = response.data.map(d => (d.percentage_minstens_een_contact * 100))
          this.consult = response.data.map(d => (d.perc_consult.toFixed(2)))
          this.visite = response.data.map(d => (d.perc_visite.toFixed(2)))
          this.kort = response.data.map(d => (d.perc_kort.toFixed(2)))
          this.poh = response.data.map(d => (d.perc_POH.toFixed(2)))

          this.consult_table = response.data.map(d => (d.perc_consult * 100).toFixed(1))
          this.visite_table = response.data.map(d => (d.perc_visite * 100).toFixed(1))
          this.kort_table = response.data.map(d => (d.perc_kort * 100).toFixed(1))
          this.poh_table = response.data.map(d => (d.perc_POH * 100).toFixed(1))

          this.leeftijd_alle = response.data.map(d => d.leeftijd)
          this.gem_contact_alle = response.data.map(d => d.gem_contact.toFixed(1))
          this.minstens_een_alle = response.data.map(d => (d.percentage_minstens_een_contact * 100).toFixed(1))
          this.load.graphOne = true
        }
      ),
        this.$http.get(`api/contacten_hap/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.gemiddeld_contact_hap = response.data.map(d => (d.gem_contact.toFixed(3) * 1000))
            this.leeftijd_dem_hap = response.data.map(d => (d.leeftijd))
            this.minstens_een_hap = response.data.map(d => (d.percentage_minstens_een_contact.toFixed(1) * 100))
            this.consult_hap = response.data.map(d => (d.perc_consult))
            this.visite_hap = response.data.map(d => (d.perc_visite))
            this.telefonisch_hap = response.data.map(d => (d.perc_telefonisch))
            this.load.graphFour = true
          }
        ),
        this.$http.get(`api/diagnoses/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.rank - a.rank)
            this.diagnose_dementie = response.data.filter(x => x.groep == 'Alles').map(d => d.patienttitel)
            this.aantal_dementie = response.data.filter(x => x.groep == 'Alles').map(d => (d.percentage_met_diagnose * 100).toFixed(0))
            response.data.sort((a, b) => a.rank - b.rank)
            this.diagnoses = response.data.filter(x => x.groep == 'Alles').map(d => d.diagnose)
            this.diagnose_dementie_1 = response.data.filter(x => x.diagnose == this.diagnoses[0]).map(d => d.groep)
            this.aantal_dementie_1 = response.data.filter(x => x.diagnose == this.diagnoses[0]).map(d => d.percentage_met_diagnose * 100)
            this.diagnose_dementie_2 = response.data.filter(x => x.diagnose == this.diagnoses[1]).map(d => d.groep)
            this.aantal_dementie_2 = response.data.filter(x => x.diagnose == this.diagnoses[1]).map(d => d.percentage_met_diagnose * 100)
            this.diagnose_dementie_3 = response.data.filter(x => x.diagnose == this.diagnoses[2]).map(d => d.groep)
            this.aantal_dementie_3 = response.data.filter(x => x.diagnose == this.diagnoses[2]).map(d => d.percentage_met_diagnose * 100)
            this.diagnose_dementie_4 = response.data.filter(x => x.diagnose == this.diagnoses[3]).map(d => d.groep)
            this.aantal_dementie_4 = response.data.filter(x => x.diagnose == this.diagnoses[3]).map(d => d.percentage_met_diagnose * 100)
            this.diagnose_dementie_5 = response.data.filter(x => x.diagnose == this.diagnoses[4]).map(d => d.groep)
            this.aantal_dementie_5 = response.data.filter(x => x.diagnose == this.diagnoses[4]).map(d => d.percentage_met_diagnose * 100)

            this.load.graphTwo = true
          }
        ),
        this.$http.get(`api/diagnoses/get_data_top5/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => a.rank - b.rank)
            this.diagnose_dementie_top5 = response.data.map(d => d.patienttitel)
            this.diagnose_alles = response.data.map(d => d.alles * 100)
            this.diagnose_65 = response.data.map(d => d.leeftijd65 * 100)
            this.diagnose_79 = response.data.map(d => d.leeftijd79 * 100)
            this.diagnose_89 = response.data.map(d => d.leeftijd89 * 100)
            this.diagnose_99 = response.data.map(d => d.leeftijd99 * 100)

            this.diagnose_alles_table = response.data.map(d => (d.alles * 100).toFixed(1))
            this.diagnose_65_table = response.data.map(d => (d.leeftijd65 * 100).toFixed(1))
            this.diagnose_79_table = response.data.map(d => (d.leeftijd79 * 100).toFixed(1))
            this.diagnose_89_table = response.data.map(d => (d.leeftijd89 * 100).toFixed(1))
            this.diagnose_99_table = response.data.map(d => (d.leeftijd99 * 100).toFixed(1))

            this.load.graphThree = true
          }
        )
      this.$http.get(`api/urgentie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a, b) => a.urgentie - b.urgentie)
          this.urgentie = response.data.map(d => d.urgentie)
          this.urgentie_percentage = response.data.map(d => d.percentage)
          this.urgentie_percentage_table = response.data.map(d => (d.percentage * 100).toFixed(1))
          this.u1 = response.data.filter(x => x.urgentie == 1).map(d => d.percentage.toFixed(3))
          this.u2 = response.data.filter(x => x.urgentie == 2).map(d => d.percentage.toFixed(3))
          this.u3 = response.data.filter(x => x.urgentie == 3).map(d => d.percentage.toFixed(3))
          this.u4 = response.data.filter(x => x.urgentie == 4).map(d => d.percentage.toFixed(3))
          this.u5 = response.data.filter(x => x.urgentie == 5).map(d => d.percentage.toFixed(3))
          this.load.graphFive = true
        }
      )
    }
  },
  mounted() {
    this.loadAllData()
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  }
}

</script>

<style scoped>
.intro-section-title {
  font-weight: bold;
  margin-bottom: 2rem;
}


.custom-link {
  color: var(--color-12);
  margin-right: 0px !important;
  outline: none;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.custom-link:link,
.custom-link:visited {
  color: var(--color-12);
}


.custom-link:hover,
.custom-link:focus {
  border-bottom: 1px solid;
  color: var(--color-2);
  background: var(--color-12);
  border-radius: .5em;
}

.blue {
  color: var(--color-1);
}

.table-bg {
  color: white;
  background-color: var(--color-1);
  border-color: var(--color-1);
}

.custom-margin {
  margin: 20px 0 20px 0;
}

.custom-text {
  margin-top: 2vw;
  font-size: 14px;
}

.custom-font {
  font-size: 12px;
}

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}
</style>